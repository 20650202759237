export default [
  {
    src: require('@images/gallery/lg/christina-yacht-0.jpg'),
    width: 2048,
    height: 1337,
  },
  {
    src: require('@images/gallery/lg/christina-yacht-1.jpg'),
    width: 2048,
    height: 1536,
  },
  {
    src: require('@images/gallery/lg/christina-yacht-2.jpg'),
    width: 2852,
    height: 1344,
  },
  {
    src: require('@images/gallery/lg/christina-yacht-3.jpg'),
    width: 1508,
    height: 1508,
  },
  {
    src: require('@images/gallery/lg/christina-yacht-5.jpg'),
    width: 2048,
    height: 1536,
  },
  {
    src: require('@images/gallery/lg/christina-yacht-6.jpg'),
    width: 2048,
    height: 1536,
  },
  {
    src: require('@images/gallery/lg/christina-yacht-7.jpg'),
    width: 2048,
    height: 1536,
  },
  {
    src: require('@images/gallery/lg/christina-yacht-4.jpg'),
    width: 1540,
    height: 1536,
  },
]
