import React from 'react'

import Gallery from 'react-photo-gallery'
import Lightbox from 'react-images'

import Lead from '@components/Lead'
import PaddedBlock from '@components/PaddedBlock'
import SEO from '@components/SEO'

import images from '@src/static/gallery'
import styles from './gallery.module.css'

class GalleryPage extends React.Component {
  state = {
    index: 0,
    open: false,
  }
  openLightbox = (event, { index }) => {
    this.setState({ index, open: true })
  }
  closeLightbox = () => {
    this.setState({ index: 0, open: false })
  }
  gotoPrevious = () => {
    this.setState(({ index }) => ({ index: index - 1 }))
  }
  gotoNext = () => {
    this.setState(({ index }) => ({ index: index + 1 }))
  }
  renderStatic() {
    return images.map(img => (
      <a key={img.src} className={styles.staticGalleryLink} href={img.src} target="_blank" rel="noopener noreferrer">
        <picture>
          <img alt="Motor Yatch Christina" src={img.src} />
        </picture>
      </a>
    ))
  }
  render() {
    return (
      <React.Fragment>
        <SEO title="Gallery" />
        <PaddedBlock>
          <Lead>
            Sit back on the deck <br />
            and enjoy cruising the Nile River
          </Lead>
          <noscript>{this.renderStatic()}</noscript>
          <Gallery photos={images} onClick={this.openLightbox} />
          <Lightbox
            images={images}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.index}
            isOpen={this.state.open}
          />
        </PaddedBlock>
      </React.Fragment>
    )
  }
}

export default GalleryPage
